<template>
    <div class="changePayPassword">
        <van-form @submit="onSubmit" class="user">
            <van-cell-group inset>
                <p class="user-name" v-show="userInfo.ISSET_DRAWPASS !== 0">原密码</p>
                <van-field v-show="userInfo.ISSET_DRAWPASS !== 0" v-model="oldpass" left-icon="user-o" type="password" name="oldpass" placeholder="请输入原密码" />

                <p class="user-name">新密码</p>
                <van-field v-model="newpass" left-icon="bag-o" type="password" name="newpass" placeholder="请输入新密码" />

                <p class="user-name">确认新密码</p>
                <van-field v-model="newpass2" left-icon="user-o" type="password" name="newpass2" placeholder="请再次输入新密码" />
            </van-cell-group>

            <div class="user-btn">
                <van-button block type="primary" native-type="submit">确定</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { changePayPassword } from '@/api'
import md5 from 'js-md5'
import { Toast } from 'vant'
import { GOPAY_USERINFO } from '@/assets/js/common.js'

export default {
    setup() {
        const state = reactive({
            oldpass: '',
            newpass: '',
            newpass2: ''
        })

        const router = useRouter()
        const store = useStore()

        const onSubmit = params => {
            if (!params.oldpass && store.state.userInfo.ISSET_DRAWPASS !== 0) {
                Toast.fail('请输入旧密码！')
                return
            } 
            if (!params.newpass) {
                Toast.fail('请输入新密码！')
                return
            }
            if (!params.newpass2) {
                Toast.fail('请确认新密码！')
                return
            }
            if (params.newpass !== params.newpass2) {
                Toast.fail('两次输入的新密码不一样！')
                return
            }
            changePayPassword({
                oldpass: md5(params.oldpass),
                newpass: md5(params.newpass)
            }).then(res => {
                if (res.status) {
                    Toast.success('支付密码设置成功！')
                    localStorage.removeItem(GOPAY_USERINFO)
                    router.push('/Home')
                } else {
                    Toast.fail(res.message)
                }
            })
        }

        return {
            ...toRefs(state),
            router,
            onSubmit,
            userInfo: computed(() => store.state.userInfo)
        }
    }
}
</script>

<style lang="scss" scoped>
.changePayPassword {
    padding: 0 w(30);

    .user {
        margin-top: h(19);
        font-size: 10px;

        .user-name {
            margin-top: h(21);
            text-align: left;
            color: #333333;
            font-size: 14px;
            line-height: 18px;
        }

        .mima {
            margin-top: h(15);
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-end;

            .user-name {
                margin-bottom: h(8);
            }

            .jzmm {
                margin-right: w(10);
                vertical-align: super;
                font-size: 12px;
                color: #828282;
            }
        }

        .user-btn {
            margin-top: h(58);
            font-size: 18px;
        }
    }
}
</style>
